<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add Schema Post
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Type"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules=""
              >
                Post
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col

            md="6"
          >

            <b-form-group
              label="Select Schema"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="cate"
                rules=""
              >

                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.schemaable_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="categoriesList"
                  @input="getSchema(addCourseForm.schemaable_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>

          <b-col md="6">
            <b-col md="12">
              <b-form-group
                label="Type "
                label-for="location-type"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  rules=""
                >
                  <v-select
                    id="LOCATION-type"
                    v-model="PostData['@type']"
                    :options="['NewsArticle','Article','BlogPosting']"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="headline"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  <b-form-input
                    id="COURSE-NAME"
                    v-model="PostData.headline"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="image"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  <b-form-input
                    id="COURSE-eventData"
                    v-model="PostData.image"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
            >
              <b-form-group
                label="date Published"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="datePublished"
                  rules="required"
                >

                  <flat-pickr
                    v-model="PostData.datePublished"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
            >
              <b-form-group
                label="date Modified"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dateModified"
                  rules="required"
                >

                  <flat-pickr
                    v-model="PostData.dateModified"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type "
                label-for="location-type"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="locationType"
                  rules=""
                >
                  <v-select
                    id="LOCATION-type"
                    v-model="PostData.author[0]['@type']"
                    :options="['Orgnization','Person']"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="author "
                label-for="location-type"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="locationType"
                  rules=""
                >
                  <b-form-input
                    id="LOCATION-locationType"
                    v-model="PostData.author[0].name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Location Name"
                label-for="location-name"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="author Url"
                  rules=""
                >
                  <b-form-input
                    id="LOCATION-name"
                    v-model="PostData.author[0].url"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup(props, { emit }) {
    // const store = useStore();
    const isLoading = ref(false)
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])
    const categoriesList = ref([])
    const scheduleList = ref([])
    const citiesList = ref([])
    const addCourseForm = reactive({
      category_id: '',
      type: 'Modules\\DynamicContent\\Entities\\Post',
      course: '',
      city: '',
      schedule: '',
      slider: '',
    })
    const dedicated = ref(false)
    // store.dispatch('categories/CategoryList').then(response => {
    //   categoriesList.value = response.data
    // })
    const getCourseSchedule = id => {
      categoriesList.value = []

      store.dispatch('blog/getAllBlog').then(response => {
      // categoriesList.value = response.data.data

        categoriesList.value = response.data.data.map(el => ({
          name: el.title,
          id: el.id,
          schema: el.schema,
        }))
      })
    }

    getCourseSchedule('Modules\\DynamicContent\\Entities\\Post')
    const getscheduleByCourse = (cityid, courseid) => {
      store
        .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
        .then(response => {
          scheduleList.value = response.data
        })
    }
    const getscheduleByCourseOnline = (cityid, courseid) => {
      if (addCourseForm.type == 'online') {
        store
          .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
          .then(response => {
            scheduleList.value = response.data
          })
      }
    }
    const typeList = [
      {
        name: 'Post',
        id: 'Modules\\DynamicContent\\Entities\\Post',
      },
      {
        name: 'Course',
        id: 'Modules\\Course\\Entities\\Course',
      },

      {
        name: 'Event',
        id: 'Modules\\Event\\Entities\\Event',
      },
    ]

    const getCoursesByCategory = id => {
      let online = null
      if (addCourseForm.type == 'online') {
        online = 1
      } else {
        online = 0
      }
      store
        .dispatch('courses/getAllCoursesList', {
          'filter[category_id]': id,
          'filter[online]': online,
        })
        .then(response => {
          coursesList.value = response.data
        })
    }

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('schemaable_type', 'Modules\\DynamicContent\\Entities\\Post')
          formData.append('schemaable_id', addCourseForm.schemaable_id.id)

          formData.append('data', JSON.stringify(PostData.value))

          isLoading.value = true
          store
            .dispatch('homepage/addSchema', formData)
            .then(response => {
              isLoading.value = false
              addCourseForm.schemaable_id=''
              PostData.value = {

                '@context': 'https://schema.org',
                '@type': '',
                headline: '',
                image: [

                ],
                datePublished: '',
                dateModified: '',
                author: [{
                  '@type': '',
                  name: '',
                  url: '',
                }],
              }

              emit('add-course')
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const getSchema = item => {
      PostData.value = JSON.parse(item.schema.data)
    }
    const eventData = ref({
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: '',
      startDate: '',
      endDate: '',
      eventAttendanceMode: '',
      eventStatus: '',
      location: {
        '@type': '',
        name: '',
        address: {
          '@type': '',
          streetAddress: '',
          addressLocality: '',
          postalCode: '',
          addressRegion: '',
          addressCountry: '',
        },
      },
      image: [

      ],
      description: '',
      offers: {
        '@type': '',
        url: '',
        price: '',
        priceCurrency: '',
        availability: '',
        validFrom: '',
      },
      performer: {
        '@type': '',
        name: '',
      },
      organizer: {
        '@type': '',
        name: '',
        url: '',
      },
    })

    const PostData = ref({

      '@context': 'https://schema.org',
      '@type': '',
      headline: '',
      image: [

      ],
      datePublished: '',
      dateModified: '',
      author: [{
        '@type': '',
        name: '',
        url: '',
      }],

    })
    const courseData = ref({

      '@context': 'https://schema.org/',
      '@id': '',
      '@type': 'Course',
      name: '',
      description: '',
      publisher: {
        '@type': 'Organization',
        name: '',
        url: '',
      },
      provider: {
        '@type': 'Organization',
        name: '',
        url: '',
      },
      image: [],
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: 0,
        ratingCount: 0,
        reviewCount: 0,
      },
      offers: [
        {
          '@type': 'Offer',
          category: '',
          priceCurrency: '',
          price: 0,
        },
      ],
      totalHistoricalEnrollment: 0,
      datePublished: '',
      educationalLevel: '',
      about: [],
      teaches: [],
      financialAidEligible: '',
      inLanguage: '',
      availableLanguage: [],
      syllabusSections: [
        {
          '@type': 'Syllabus',
          name: '',
          description: '',
          timeRequired: '',
        },
        {
          '@type': 'Syllabus',
          name: '',
          description: '',
          timeRequired: '',
        },
      ],
      review: [
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: '',
          },
          datePublished: '',
          reviewRating: {
            '@type': 'Rating',
            bestRating: 0,
            ratingValue: 0,
          },
        },
      ],
      coursePrerequisites: [],
      educationalCredentialAwarded: [
        {
          '@type': 'EducationalOccupationalCredential',
          name: '',
          url: '',
          credentialCategory: '',
          offers: [
            {
              '@type': 'Offer',
              category: '',
              price: 0,
              priceCurrency: '',
            },
          ],
        },
      ],
      video: {
        '@type': 'VideoObject',
        name: '',
        description: '',
        uploadDate: '',
        contentUrl: '',
        thumbnailUrl: '',
      },
      hasCourseInstance: [
        {
          '@type': 'CourseInstance',
          courseMode: '',
          location: '',
          courseSchedule: {
            '@type': 'Schedule',
            duration: '',
            repeatFrequency: '',
            repeatCount: 0,
            startDate: '',
            endDate: '',
          },
          instructor: [
            {
              '@type': 'Person',
              name: '',
              description: '',
              image: '',
            },
          ],
        },
        {
          '@type': 'CourseInstance',
          courseMode: '',
          courseWorkload: '',
        },
      ],
      hasPart: [
        {
          '@type': 'Course',
          name: '',
          url: '',
          description: '',
          provider: {
            '@type': 'Organization',
            name: '',
            url: '',
          },
        },
        {
          '@type': 'Course',
          name: '',
          url: '',
          description: '',
          provider: {
            '@type': 'Organization',
            name: '',
            url: '',
          },
        },
      ],

    })
    return {
      eventData,
      getSchema,
      courseData,
      isLoading,
      PostData,
      refInputEl,
      dedicated,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      typeList,
      categoriesList,
      getCoursesByCategory,
      citiesList,
      getCourseSchedule,
      scheduleList,
      getscheduleByCourseOnline,
      getscheduleByCourse,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
